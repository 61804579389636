import * as Yup from 'yup';
import { getString } from 'util/lang';
import regexPattern from '../constants/regexPattern';

export const AbnFormSchema = () => ({
  orderItemId: Yup.string()
    .trim()
    .required(
      getString('dme.validation.requiredFieldMessage', {
        field: 'order item id',
      }) as string,
    ),
  // Previous implementation of abnSignature is no longer supported. Replaced by drawable signature as abnCanvasSignature
  abnCanvasSignature: Yup.string()
    .trim()
    .required(
      getString('dme.validation.requiredFieldMessage', {
        field: getString('dme.documentation.abnForm.title'),
      }) as string,
    ),
  abnSignedDate: Yup.string()
    .trim()
    .required(
      getString('dme.validation.emptyDate', {
        field: getString('dme.documentation.abnForm.title'),
      }) as string,
    )
    .matches(
      /^\d{2}\/\d{2}\/\d{4}$/,
      getString('dme.validation.invalidDateFormat') as string,
    ),
});

export const ESignatureSchema = () => ({
  eSignature: Yup.string()
    .trim()
    .required(
      getString('dme.validation.requiredFullName', {
        field: getString(
          'dme.documentation.electronicSignatuteAcceptance.title',
        ),
      }) as string,
    )
    .matches(
      regexPattern.nameField,
      getString('dme.validation.invalidFieldMessage', {
        field: getString(
          'dme.documentation.electronicSignatuteAcceptance.title',
        ),
      }) as string,
    ),
});

export const TermsAndConditionSchema = () => ({
  // Previous implementation of tncFullName is no longer supported. Replaced by drawable signature as tncSignature
  tncSignature: Yup.string()
    .trim()
    .required(
      getString('dme.validation.requiredFieldMessage', {
        field: getString('dme.documentation.termsAndConditions.title'),
      }) as string,
    ),
  tncAddress: Yup.string()
    .trim()
    .matches(regexPattern.addressField, {
      message: getString('dme.validation.invalidFieldMessage', {
        field: getString('dme.documentation.termsAndConditions.title'),
      }) as string,
      excludeEmptyString: true,
    }),
  acceptTnc: Yup.boolean().oneOf(
    [true],
    getString('dme.validation.tncAccept') as string,
  ), // Validates that the value is true
  tncDate: Yup.string()
    .trim()
    .required(
      getString('dme.validation.emptyDate', {
        field: getString('termsAndCondition'),
      }) as string,
    )
    .matches(
      /^\d{2}\/\d{2}\/\d{4}$/,
      getString('dme.validation.invalidDateFormat') as string,
    ),

  tncRelation: Yup.number(),

  tncReasonUnableToSign: Yup.string().trim(),
});
