import { StyleSheet } from '@react-pdf/renderer';
import { printFontFamily } from 'constants/dme';

export const useStyles = StyleSheet.create({
  container: {
    height: '100vh',
  },
  pdfViewer: {
    height: '100%',
    width: '100%',
  },
  page: {
    padding: '24px 16px',
    fontSize: '11px',
  },

  title: {
    fontSize: 16,
    fontFamily: printFontFamily,
    fontWeight: 700,
    textAlign: 'center',
    marginBottom: 20,
  },
  textWrap: {
    marginBottom: 16,
  },
  text: {
    fontSize: 12,
    fontFamily: printFontFamily,
    fontWeight: 700,
  },
  subText: {
    fontSize: 11,
    fontFamily: printFontFamily,
    fontWeight: 400,
    marginTop: 4,
  },
  subTextBold: {
    fontSize: 11,
    fontFamily: printFontFamily,
    fontWeight: 700,
  },
  agreeWrap: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  img: {
    height: 10,
    width: 10,
    marginRight: 8,
  },
  agreeText: {
    fontSize: 11,
    fontFamily: printFontFamily,
    fontWeight: 400,
    marginLeft: 8,
    marginTop: -1,
  },
  signWrap: {
    border: '1px solid #E1DFDD',
    padding: '8px 12px',
    display: 'flex',
    flexDirection: 'row',
    marginTop: 16,
  },
  signLeft: {
    flex: 5,
    display: 'flex',
    flexDirection: 'column',
  },
  signRight: {
    flex: 2,
    borderLeft: '1px solid #E1DFDD',
    paddingLeft: 16,
  },
  signTitle: {
    fontSize: 11,
    fontFamily: printFontFamily,
    fontWeight: 700,
    marginBottom: 8,
  },
  signName: {
    fontSize: 10,
    fontFamily: printFontFamily,
    fontWeight: 400,
    borderBottom: '1px solid #605E5C',
    maxWidth: 215,
    paddingLeft: 4,
    paddingBottom: 6,
  },
  signImage: {
    maxWidth: 215,
    paddingLeft: 4,
    paddingBottom: 6,
  },
  addressWrap: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    marginTop: 16,
  },
  addressLeft: {
    flex: 5,
    minHeight: 20,
    display: 'flex',
    flexDirection: 'column',
  },
  addressRight: {
    flex: 2,
    paddingLeft: 16,
  },
  reasonWrap: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 16,
  },
  reason: {
    fontSize: 10,
    fontFamily: printFontFamily,
    fontWeight: 400,
    borderBottom: '1px solid #605E5C',
    paddingLeft: 4,
    paddingBottom: 6,
  },
  footerWrap: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    borderTop: '1px solid #000000',
    paddingTop: 4,
    marginTop: '48px',
    position: 'absolute',
    bottom: 16,
    left: 16,
  },
  footerText: {
    fontSize: 10,
    fontFamily: printFontFamily,
    fontWeight: 400,
  },
  footerTextBold: {
    fontSize: 10,
    fontFamily: printFontFamily,
    fontWeight: 700,
    marginRight: 4,
  },
});
