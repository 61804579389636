import { Font, Link, StyleSheet, Text, View } from '@react-pdf/renderer';

import { getString } from 'util/lang';

import FormHeaderPdfView from '../../common/FormHeader/FormHeaderPdfView';
import PharmacyInfoPdfView from '../../common/PharmaInfo/PharmaInfoPdfView';
import DMESignaturePdfView from '../../common/DMESignature/DMESignaturePdfView';
import { BeneficiaryRelation } from 'constants/productOrder';
import {
  printFontBoldCDN,
  printFontFamily,
  printFontRegularCDN,
} from 'constants/dme';

const LABEL_PREFIX = 'dme.deliveryPacketForm.authorizationForm';

const AuthorizationFormPdfView = ({
  organizationDetail,
  authorizationFormData,
}) => {
  Font.register({
    family: printFontFamily,
    fonts: [
      {
        src: printFontRegularCDN,
        fontWeight: 400,
      },
      {
        src: printFontBoldCDN,
        fontWeight: 700,
      },
    ],
  });
  const { organizationName, address, organizationContactPhone } =
    organizationDetail;

  const addAuthorization = JSON.parse(
    getString('dme.deliveryPacketForm.authorizationForm.addAuthorization', {
      companyName: organizationName,
      phoneNumber: organizationContactPhone,
    }),
  );

  return (
    <View style={styles.container}>
      <View style={{ marginTop: 12 }} />
      <FormHeaderPdfView text={getString(`${LABEL_PREFIX}.title`)} />
      <PharmacyInfoPdfView
        name={organizationName}
        address={address}
        contact={organizationContactPhone}
      />
      <View style={{ marginBottom: 12 }} />
      <View>
        {addAuthorization?.map((item, index) => {
          return (
            <View key={index} style={styles.sectionWrap}>
              <Text style={styles.h3}>{item.title}</Text>
              {item.title === 'Supplier Standards Disclosure' ? (
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                  }}
                >
                  <Text style={styles.text}>
                    {item.pdfDescBeforeLinkForPrint}
                    <Link
                      style={{
                        ...styles.text,
                        color: '#106EBE',
                      }}
                      href="https://www.ecfr.gov/cgi-bin/text-idx?c=ecfr&SID=8faa91945881bf476ca44232fb4eb75e&rgn=div8&view=text&node=42:3.0.1.1.11.4.5.8&idno=42"
                      wrap={true}
                    >
                      U.S. Government Printing Office website
                    </Link>{' '}
                    {item.pdfDescAfterLinkForPrint}
                  </Text>
                </View>
              ) : (
                <View>
                  <Text style={styles.text}>
                    {item.description}{' '}
                    <Text style={styles.subTextBold}>{item?.meta}</Text>
                  </Text>
                </View>
              )}
            </View>
          );
        })}
      </View>
      <DMESignaturePdfView
        title={getString(`${LABEL_PREFIX}.signatureTitle`)}
        style={{
          marginTop: 10,
        }}
        signature={authorizationFormData?.tncFullName}
        signatureImage={authorizationFormData?.tncSignature}
        signedDate={authorizationFormData?.tncDate}
        showDate={true}
      />
      <View style={styles.clientWrap}>
        <View style={styles.clientDetailWrap}>
          <Text style={styles.h3}>
            {getString(`${LABEL_PREFIX}.fields.address.label`)}:{' '}
          </Text>
          <Text style={styles.text}>{authorizationFormData?.tncAddress}</Text>
        </View>
        <View style={styles.clientDetailWrap}>
          <Text style={styles.h3}>
            {getString(`${LABEL_PREFIX}.fields.relationship.label`)}:{' '}
          </Text>
          <Text style={styles.text}>
            {
              BeneficiaryRelation.find(
                (beneficiary) =>
                  beneficiary.value === authorizationFormData?.tncRelation,
              )?.label
            }
          </Text>
        </View>
        <View style={styles.clientDetailWrap}>
          <Text style={styles.h3}>
            {getString(`${LABEL_PREFIX}.fields.reason.label`)}:{' '}
          </Text>
          <Text style={styles.text}>
            {authorizationFormData?.tncReasonUnableToSign}
          </Text>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  clientWrap: {
    marginTop: 15,
  },
  clientDetailWrap: {
    display: 'flex',
    flexDirection: 'row',
  },
  sectionWrap: {
    marginBottom: 10,
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
  },
  h3: {
    fontSize: 9,
    fontWeight: 700,
    fontFamily: printFontFamily,
    marginBottom: 3,
  },
  text: {
    fontSize: 9,
    fontWeight: 400,
    fontFamily: printFontFamily,
    color: '#323130',
  },
  subTextBold: {
    fontSize: 9,
    fontWeight: 700,
    fontFamily: printFontFamily,
  },
});

export default AuthorizationFormPdfView;
